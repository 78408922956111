import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledToast = styled(Box)`
  .toast {
    background: rgb(245, 248, 243);
    padding: 1.2rem 1.6rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    border-radius: 0.8rem;
    width: 50.2rem;
    max-width: 62.2rem;
    border: 1px solidrgb(180, 236, 145);

    @media (max-width: 576px) {
      min-width: 30.8rem;
      width: 90%;
    }

    &-text {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .toast-error {
    background: #ffe9ed;

    .toast-text {
      color: #c8102e;
    }
  }
`
