import cn from 'classnames'
import { useState } from 'react'

import { Box, FormControl, FormLabel, IconButton, RadioGroup, Typography, useMediaQuery } from '@mui/material'

import InfoIcon from '../../assets/icons/InfoIcon'
import RadioButton from '../RadioButton'
import SmallRadioButton from '../SmallRadioButton'
import CustomTooltip from '../Tooltip'
import TooltipModal from '../TooltipModal'
import { StyledRadioButtonGroup } from './styled'

const RadioButtonsGroup = ({
  label,
  isSmall,
  isMostPopular,
  isColumn,
  tooltip,
  tooltipProps,
  modalTitle,
  modalProps,
  className,
  value,
  defaultValue,
  groupName,
  options,
  onChange,
  disabled,
  handleOpenJoinModal,
  ...props
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const [isOpenTooltipModal, setIsOpenTooltipModal] = useState(false)

  const handleOpenTooltipModal = () => setIsOpenTooltipModal(isOpenTooltipModal => !isOpenTooltipModal)

  return (
    <StyledRadioButtonGroup {...props}>
      <FormControl>
        <FormLabel id='demo-radio-buttons-group-label' className='radio-buttons-label'>
          {tooltip ? (
            <Box className='radio-buttons-label-box'>
              <Typography className='radio-buttons-label'>{label}</Typography>
              <CustomTooltip title={tooltip} componentsProps={tooltipProps}>
                <IconButton
                  className='radio-buttons-content-tooltip'
                  onClick={isSmallScreen ? handleOpenTooltipModal : null}
                >
                  <InfoIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : (
            <Typography className='radio-buttons-label'>{label}</Typography>
          )}
        </FormLabel>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          name='radio-buttons-group'
          id={groupName}
          onChange={onChange}
          disabled={disabled}
          value={value ?? ''}
          defaultValue={defaultValue ?? value ?? ''}
          className={cn('radio-buttons-group', { isSmall: isSmall, isColumn: isColumn, isMostPopular: isMostPopular })}
          {...props}
        >
          {options.map(item =>
            isSmall ? (
              <SmallRadioButton
                key={item.value}
                label={item.label}
                value={value}
                itemValue={item.value}
                price={item.price}
                mostPopular={item.mostPopular}
                isColumn={isColumn}
                tooltip={item?.tooltip}
                disabled={item.isSoldOut}
                className={item.isSoldOut ? 'sold-out' : ''}
                isSoldOut={item.isSoldOut}
                handleOpenJoinModal={handleOpenJoinModal}
              />
            ) : (
              <RadioButton
                key={item.value}
                label={item.label}
                value={value}
                itemValue={item.value}
                isDiscount={item.isDiscount}
                discount={item.discount}
                tooltip={item.tooltip}
                tooltipWidth={item.tooltipWidth}
                info={item.info}
                duration={item.duration}
                discountPrice={item.discountPrice}
                price={item.price}
              />
            )
          )}
        </RadioGroup>
      </FormControl>
      <TooltipModal
        open={isOpenTooltipModal}
        title={modalTitle}
        componentsProps={modalProps}
        onClose={handleOpenTooltipModal}
      >
        {tooltip}
      </TooltipModal>
    </StyledRadioButtonGroup>
  )
}

export default RadioButtonsGroup
