import cn from 'classnames'
import { useState } from 'react'

import { Box, FormControlLabel, IconButton, Radio, Typography, useMediaQuery } from '@mui/material'

import CheckedIcon from '../../assets/icons/CheckedIcon'
import CircleIcon from '../../assets/icons/CircleIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import CustomTooltip from '../Tooltip'
import TooltipModal from '../TooltipModal'
import { StyledSmallRadioButton } from './styled'

const SmallRadioButton = ({
  label,
  value,
  price,
  mostPopular,
  isColumn,
  itemValue,
  tooltip,
  disabled,
  isSoldOut,
  handleOpenJoinModal,
  ...props
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const [isOpenTooltipModal, setIsOpenTooltipModal] = useState(false)

  const handleOpenTooltipModal = () => setIsOpenTooltipModal(isOpenTooltipModal => !isOpenTooltipModal)

  return (
    <StyledSmallRadioButton isColumn={isColumn} {...props}>
      <FormControlLabel
        key={`radio-button-${itemValue}`}
        className={cn('radio-buttons-small-button', {
          checked: value == itemValue.toString(),
          mostPopular: mostPopular
        })}
        sx={{
          '.MuiFormControlLabel-label': {
            width: '100%'
          }
        }}
        disabled={disabled}
        value={itemValue}
        control={<Radio icon={<CircleIcon />} checkedIcon={<CheckedIcon />} checked={value == itemValue.toString()} />}
        label={
          <Box className='radio-buttons-label-box'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isSoldOut ? 'space-between' : 'flex-start',
                width: isSoldOut ? '100%' : 'auto'
              }}
            >
              <Typography className='radio-buttons-small-label'>{label}</Typography>

              {isSoldOut && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#d53d3d',
                      display: 'block'
                    }}
                  >
                    Sold Out
                  </Typography>
                  <Typography
                    onClick={handleOpenJoinModal ? () => handleOpenJoinModal({ label, value }) : () => {}}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      display: 'block',
                      cursor: 'pointer',
                      color: 'rgb(45, 73, 96)',
                      textDecoration: 'underline'
                    }}
                  >
                    Join Waitlist
                  </Typography>
                </Box>
              )}
              {tooltip && (
                <CustomTooltip title={tooltip} width='402px'>
                  <IconButton
                    className='radio-buttons-content-tooltip'
                    onClick={isSmallScreen ? handleOpenTooltipModal : null}
                  >
                    <InfoIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </Box>
            {mostPopular && <Box className='radio-buttons-most-popular'>Most popular</Box>}
            {price && (
              <Box>
                <Typography className='radio-buttons-small-label'>
                  £{new Intl.NumberFormat('en-IN').format(price)}
                </Typography>
              </Box>
            )}
          </Box>
        }
      ></FormControlLabel>

      <TooltipModal
        open={isOpenTooltipModal}
        onClose={handleOpenTooltipModal}
        componentsProps={{
          '.tooltip-header': {
            marginBottom: '4px',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 600
          },

          '.tooltip-text': {
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 300
          }
        }}
      >
        {tooltip}
      </TooltipModal>
    </StyledSmallRadioButton>
  )
}

export default SmallRadioButton
