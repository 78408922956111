import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Typography,
  useMediaQuery
} from '@mui/material'

import ArrowRight from '../../assets/icons/ArrowRight'
import ListIcon from '../../assets/icons/ListIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import Autocomplete from '../../components/Autocomplete'
import { StyledAutocomplete } from '../../components/Autocomplete/Autocomplete.styled'
import CustomButton from '../../components/Button'
import Form from '../../components/Form'
import Summary from '../../components/Summary'
import Input from '../../components/TextField'
import { countries } from '../../constants/participantDetailsData'
import useDebounce from '../../hooks/useDebounce'
import usePlacesAutocompleteService from '../../hooks/usePlacesAutocomplete'
import { addAcademicDetails } from '../../store/enrolment/enrolment.actions'
import { selectMainMainOrderId, selectOrder, selectStudentDetails } from '../../store/enrolment/enrolment.selectors'
import { Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const AcademicDetailsScreen = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const dispatch = useDispatch()

  const currentStudentDetails = useSelector(selectStudentDetails)
  const order = useSelector(selectOrder)
  const orderId = useSelector(selectMainMainOrderId)

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState({
    types: ['school', 'secondary_school', 'university'],

    componentRestrictions: {
      country: currentStudentDetails?.country
        ? countries.find(({ label }) => label === currentStudentDetails?.country)?.code
        : ''
    }
  })
  const [code, setCode] = useState(
    currentStudentDetails?.country ? countries.find(({ label }) => label === currentStudentDetails?.country)?.code : ''
  )

  const { placesService, placePredictions, getPlacePredictions } = usePlacesAutocompleteService({
    apiKey: 'AIzaSyC-NDIFH3gVOSOPxZ5p8twSVNzSagoVQgM',
    language: 'en',
    options: options
  })

  const [value, setValue] = React.useState(currentStudentDetails?.placesSchoolData || null)
  const [inputValue, setInputValue] = React.useState(currentStudentDetails?.placesSchoolData?.schoolName || '')

  const debouncedSearchValue = useDebounce(inputValue, 500)

  const initialState = useMemo(
    () => ({
      ...currentStudentDetails,
      schoolName: currentStudentDetails?.placesSchoolData?.schoolName || ''
    }),
    [currentStudentDetails]
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    formState: { errors }
  } = useForm({ defaultValues: initialState })

  const { field: country } = useController({
    name: 'country',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: nameOfSchool } = useController({
    name: 'schoolName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: motivation } = useController({
    name: 'motivation',
    control: control
    // rules: {
    //   required: { value: true, message: 'This field is required' }
    // }
  })

  const { field: commitmentConfirmation } = useController({
    name: 'commitmentConfirmation',
    control: control
  })

  const selectCountry = async selectedCountry => {
    if (selectedCountry?.code) {
      setCode(selectedCountry.code)

      setOptions(prev => ({ ...prev, componentRestrictions: { country: selectedCountry.code } }))
      getPlacePredictions({ input: debouncedSearchValue })
    }

    const countryForChange = selectedCountry?.label || selectedCountry
    country.onChange(countryForChange)

    if (countryForChange && countryForChange !== country.value) {
      nameOfSchool.onChange('')
      setInputValue('')
      setValue(null)
    }
  }

  const onSubmit = async data => {
    const paramsUrl = params.get('navigate')
    const navigateUrl = paramsUrl ? `/${paramsUrl}` : `/parent-guardian-details`

    placesService?.getDetails(
      {
        placeId: value.place_id || value.googlePlacesId
      },
      placeDetails => {
        const locality = placeDetails.address_components.filter(
          f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political'])
        )[0]?.long_name

        const state = placeDetails.address_components.filter(
          f => JSON.stringify(f.types) === JSON.stringify(['administrative_area_level_1', 'political'])
        )[0]?.long_name

        const schoolCountry = placeDetails.address_components.filter(
          f => JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.long_name

        const schoolWebsite = placeDetails.website || null

        const schoolAddress = placeDetails.formatted_address || null

        const schoolPostcode =
          placeDetails.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['postal_code']))[0]
            ?.long_name || ''

        let city = locality || state

        if (isDirty) {
          dispatch(
            addAcademicDetails({
              orderId,
              body: {
                ...data,
                schoolWebsite,
                schoolAddress,
                schoolPostcode,
                schoolCity: city || '',
                googlePlacesId: placeDetails.place_id,
                schoolCountry: schoolCountry || data.country
              },
              callback: () => {
                setIsLoading(false)
                navigate(navigateUrl)
              },
              callbackRejected: () => setIsLoading(false)
            })
          )
        } else {
          navigate(navigateUrl)
        }
      }
    )
  }

  useEffect(() => {
    getPlacePredictions({ input: debouncedSearchValue })
  }, [debouncedSearchValue, code])

  useEffect(() => {
    if (!currentStudentDetails?.country) {
      fetch('https://ipapi.co/json/')
        .then(function (response) {
          response.json().then(jsonData => {
            const code = jsonData?.country_code

            if (code) {
              const foundedCountry = countries.find(country => country.code === code)

              foundedCountry && selectCountry(foundedCountry)
            }

            if (window.google.maps) {
              const circle = new window.google.maps.Circle({
                center: { lat: jsonData.latitude, lng: jsonData.longitude },
                radius: 100000
              })

              setOptions(prev => ({
                ...prev,
                locationBias: circle.getBounds()
              }))
            }
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [])

  return (
    <Wrapper>
      {isSmallScreen && (
        <Accordion
          className='accordion'
          disableGutters
          elevation={0}
          sx={{
            '&.Mui-expanded:first-of-type': {
              marginTop: '16px'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
            <ListIcon />

            <Typography className='accordion-title'>Summary</Typography>

            <Typography className='accordion-title accordion-title-price'>
              £{new Intl.NumberFormat('en-IN').format(order.totalOrderAmount)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <Summary />
          </AccordionDetails>
        </Accordion>
      )}

      <Typography className='title' component='h1'>
        Student’s Academic Details
      </Typography>

      <Typography className='text'>
        Complete the details for the student who will be taking the Immerse course
      </Typography>

      <Form
        className='form'
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <CustomButton isLight onClick={() => navigate(-1)}>
              Back
            </CustomButton>

            <CustomButton type='submit' disabled={isLoading}>
              Next <ArrowRight />
            </CustomButton>
          </>
        }
      >
        <Box className='form-col'>
          <Autocomplete
            className='form-item'
            error={errors?.country?.message}
            onSelect={selectCountry}
            options={countries}
            placeholder='Country of residence'
            initialValue={country.value}
            initialInputValue={country.value}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading='lazy'
                  width='20'
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=''
                />
                {option.label}
              </Box>
            )}
          />

          <StyledAutocomplete
            value={value}
            onChange={(event, newValue) => {
              if (newValue?.googlePlacesId) {
                nameOfSchool.onChange(newValue.schoolName)
              } else {
                nameOfSchool.onChange(newValue?.structured_formatting?.main_text)
              }

              setValue(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
              // getPlacePredictions({ input: newInputValue })
            }}
            className='form-item'
            id='school-autocomplete'
            fullWidth
            options={!debouncedSearchValue ? [] : placePredictions}
            getOptionLabel={o => o.description || o.schoolName}
            renderInput={params => {
              return (
                <Input
                  {...params}
                  value={params.inputProps.value}
                  onChange={params.inputProps.onChange}
                  placeholder={'Enter a school'}
                  InputProps={{
                    ...params.InputProps,
                    className: '',
                    endAdornment: <SelectAngleIcon onClick={params.InputProps.onClick} />
                  }}
                  inputProps={{ ...params.inputProps, className: '' }}
                  error={errors.schoolName?.message}
                />
              )
            }}
            slotProps={{
              paper: { elevation: 8, sx: { backgroundColor: 'transparent' } },
              clearIndicator: { sx: { display: 'none' } }
            }}
          />

          <Box className='form-col' sx={{ alignItems: 'flex-end' }}>
            <Input
              label='Please explain why you want to study your chosen programme, what you hope to gain from it, and how it aligns with your future goals. This statement will be shared with your tutor to help them understand your aspirations. Our programmes are designed for motivated students preparing for their future.'
              placeholder='Your motivation statement'
              rows={3}
              maxRows={7}
              multiline
              containerClass='form-item-full'
              value={motivation.value}
              onChange={motivation.onChange}
              error={errors?.motivation?.message}
            />

            <Box>
              <InputLabel
                shrink={false}
                sx={{
                  lineHeight: '21px',
                  whiteSpace: 'initial',
                  marginBottom: '0.8rem',
                  fontSize: '1.4rem',
                  fontWeight: '450',
                  color: '#6F6F6F'
                }}
              >
                Confirm your commitment to completing the preparatory course material (checkbox)
              </InputLabel>

              <FormControlLabel
                className={classNames('checkbox-button', { checked: !!commitmentConfirmation.value })}
                control={
                  <Checkbox
                    sx={{
                      color: '#D5DADF',
                      '&.Mui-checked': {
                        color: '#B8945C'
                      },
                      '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                    checked={!!commitmentConfirmation.value}
                    onChange={e => commitmentConfirmation.onChange(e.target.checked)}
                  />
                }
                label={
                  <Typography className='checkbox-label'>
                    Preparatory material is essential for your success in the programme and must be completed before the
                    course begins. Please confirm your commitment to reviewing and completing this material in advance.
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Box>
      </Form>
    </Wrapper>
  )
}

export default AcademicDetailsScreen
