import React, { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'

import { Box, IconButton, Modal } from '@mui/material'

import CrossMarkIcon from '../../../../assets/icons/CrossmarkIcon'

import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    outline: none;
    border-radius: 0px;
    padding: 32px;
    width: 450px;
    max-width: 90%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .modal-title {
    font-size: 40px;
    font-weight: 300;
    color: #181c2a;
    text-align: center;
    width: 100%;
  }

  .modal-description {
    font-size: 14px;
    color: #8a8a8a;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
  }

  /* Custom styling for HubSpot form */
  .hubspot-form {
    margin-top: 10px;
  }

  .hubspot-form input {
    width: 100% !important;
    padding: 12px;
    outline: none;
    border: 0px solid #fff;
    border-bottom: 1px solid #8a8a8a;
    border-radius: 0px;
    margin-top: 0px;
    font-size: 14px;
  }

  .hubspot-form .hs-firstname .input {
    margin-right: 16px !important;
  }

  .hubspot-form input:focus {
    width: 100% !important;

    border: 0px solid #fff !important;
    outline: none;
    border-bottom: 1px solid #8a8a8a !important;
    border-radius: 0px;
  }

  .hubspot-form input::placeholder {
    font-size: 12px;
    color: #696969;
  }

  .hubspot-form label {
    font-size: 16px;
    color: #696969;
  }

  .hubspot-form .hs-error-msg {
    color: #c8102e;
  }

  .hubspot-form .field {
    margin-top: 24px;
  }

  .hubspot-form textarea,
  .hubspot-form select {
    width: 100% !important;
    padding: 12px;
    border: 1px solid #8a8a8a;
    outline: none;
    border-radius: 0px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .hubspot-form .input {
    margin-right: 0px !important;
  }

  .hubspot-form .hs-button {
    width: 100%;
    background-color: #fff;
    color: #181c2a;
    border-radius: 50px;
    border: 1px solid #181c2a;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 28px;
    transition: 0.3s ease;
  }

  .hubspot-form .hs-button:hover {
    color: #fff;
    background-color: #2d4960 !important;
  }

  .hubspot-form .submitted-message {
    color: #181c2a;
    text-align: center;
    font-size: 20px;
  }
`

const JoinModal = ({ open, onClose, city, dates, subject, location }) => {
  const formContainerRef = useRef(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
    script.type = 'text/javascript'
    script.charset = 'utf-8'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '144908688',
          formId: '5ded02f3-9370-4fc0-9265-0c98eb7597df',
          region: 'eu1',
          target: '#hubspot-form-container',
          onFormSubmit: () => {
            toast.success('Thank you, we will contact you!')
          }
        })
      }
    }

    return () => {
      document.body.removeChild(script)
      if (formContainerRef.current) {
        formContainerRef.current.innerHTML = ''
      }
    }
  }, [open])

  useEffect(() => {
    const updateHiddenFields = () => {
      const form = document.querySelector('#hubspot-form-container form')
      if (form) {
        let dateField = form.querySelector('input[name="waitlist_dates"]')
        let locationField = form.querySelector('input[name="waitlist_location"]')
        let subjectField = form.querySelector('input[name="waitlist_subject"]')

        if (!dateField) {
          dateField = document.createElement('input')
          dateField.type = 'hidden'
          dateField.name = 'waitlist_dates'
          form.appendChild(dateField)
        }
        if (!locationField) {
          locationField = document.createElement('input')
          locationField.type = 'hidden'
          locationField.name = 'waitlist_location'
          form.appendChild(locationField)
        }
        if (!subjectField) {
          subjectField = document.createElement('input')
          subjectField.type = 'hidden'
          subjectField.name = 'waitlist_subject'
          form.appendChild(subjectField)
        }

        dateField.value = dates || ''
        locationField.value = location || ''
        subjectField.value = subject || ''
      }
    }

    const handleMessage = event => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
        updateHiddenFields()
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [dates, subject, location])

  return (
    <StyledModal open={open} onClose={onClose}>
      <Box className='modal-body'>
        <div className='modal-header'>
          <h2 className='modal-title'>Join the waiting list</h2>
          <IconButton onClick={onClose} size='small' sx={{ position: 'absolute', top: '-20px', right: '-20px' }}>
            <CrossMarkIcon />
          </IconButton>
        </div>

        <p className='modal-description'>
          Be the first to know when applications open. Secure your spot on the waiting list for an unforgettable
          educational experience in {city}.
        </p>

        <div id='hubspot-form-container' className='hubspot-form' ref={formContainerRef}></div>
      </Box>
    </StyledModal>
  )
}

export default JoinModal
